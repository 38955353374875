import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#Light",
      main: "#f50057",
      dark: "#bb002f",
      contrastText: "#fff",
    },
  },
  typography: {},
});

export default theme;
