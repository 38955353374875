import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* ordersWorker({ payload }) {
  const { page, rowsPerPage, query } = payload;
  // call api with parameters
  const response = yield call(api.getOrders, {
    ...query,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    ordering: "internal_data__created",
    //dadata__deliverability: 'DELIVERABLE',
  });

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}

export default function* ordersSaga() {
  yield all([takeLatest(sendRequest.type, ordersWorker)]);
}
