import { createGenericSlice } from "helpers/factories";

const businessEntitiesSlice = createGenericSlice("businessEntities");

export const { actions } = businessEntitiesSlice;

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  businessEntitiesSlice.actions;

// this is a convention to export reducer by default from slice
export default businessEntitiesSlice.reducer;
