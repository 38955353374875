import { combineReducers } from "redux";
import drawerReducer from "./Drawer/slice";
import errorReducer from "./Error/slice";
import loginReducer from "./Login/slice";

// Orders
import countriesReducer from "../Orders/Countries/slice";
import orderActionsReducer from "../Orders/Actions/slice";
import ordersCarriersReducer from "../Orders/Carriers/slice";
import ordersCountersReducer from "../Orders/Counters/slice";
import ordersReducer from "../Orders/slice";
import tagsReducer from "../Orders/Tags/slice";

// Finances
import financesAccountsReducer from "../Finances/Accounts/slice";
import financesActionsReducer from "../Finances/Actions/slice";
import financesBusinessEntitiesReducer from "../Finances/BusinessEntities/slice";
import financesCampaignsReducer from "../Finances/Campaigns/slice";
import financesTransactionCategoriesReducer from "../Finances/TransactionCategories/slice";
import financesTransactionsReducer from "../Finances/slice";

export const rootReducer = combineReducers({
  login: loginReducer,
  drawer: drawerReducer,
  error: errorReducer,
  financesActions: financesActionsReducer,
  financesAccounts: financesAccountsReducer,
  financesBusinessEntities: financesBusinessEntitiesReducer,
  financesCampaigns: financesCampaignsReducer,
  financesTransactionCategories: financesTransactionCategoriesReducer,
  financesTransactions: financesTransactionsReducer,
  orderCarriers: ordersCarriersReducer,
  orderCounters: ordersCountersReducer,
  actions: orderActionsReducer, // rename to orderActions
  orders: ordersReducer,
  countries: countriesReducer,
  tags: tagsReducer,
});
