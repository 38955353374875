import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { LAYOUT_DRAWER_WIDTH } from "config/constants";
import { browserRedirect } from "helpers/helpers";
import { connect } from "react-redux";
import { init as resetError } from "modules/App/Error/slice";
import { setDrawerOpen } from "modules/App/Drawer/slice";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Error from "../Error";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect } from "react";
import logo from "assets/82boxlogo.png";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: LAYOUT_DRAWER_WIDTH,
    width: `calc(100% - ${LAYOUT_DRAWER_WIDTH}px)`,
  }),
}));

const PrivateLayout = ({
  children,
  drawer,
  error,
  location,
  setDrawerOpenState,
  resetErrorState,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    // this one reset error state on every location change
    resetErrorState();
  }, [location, resetErrorState]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpenState(true);
  };

  if (error.code === 403) {
    return (
      <Error
        error={error}
        message={
          <span>
            <strong>Нэ можно!</strong>
          </span>
        }
      />
    );
  }

  if (error.code !== 0) {
    return (
      <Error
        error={error}
        message={
          <span>
            <strong>Оушит!</strong> Что-то сильно поломалось!
          </span>
        }
      />
    );
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" open={drawer.open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(drawer.open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <img width="100" alt="logo" src={logo} />
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: "right" }}>
            <Button
              onClick={() => browserRedirect("/")}
              variant="contained"
              color="primary"
              disableElevation
            >
              Заказы
            </Button>
            <Button
              onClick={() => browserRedirect("/finances")}
              variant="contained"
              color="primary"
              disableElevation
            >
              Финансы
            </Button>
          </Box>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push("/logout")}>Выйти</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    drawer: state.drawer,
    error: state.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDrawerOpenState: (state) => dispatch(setDrawerOpen(state)),
    resetErrorState: (state) => dispatch(resetError(state)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateLayout));
