import apisauce from "apisauce";

const API_ROOT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const create = (baseURL = API_ROOT) => {
  // dynamic options, we need to read localStorage on every request
  const getOptions = () => {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  };

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {},
    // 20 second timeout...
    timeout: 20000,
  });

  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  const login = (params) => api.post("/token/", params, getOptions());
  const getTags = (params) => api.get("/v1/tags/", params, getOptions());
  const getCountries = (params) =>
    api.get("/v1/countries/", params, getOptions());
  const getOrders = (params) => api.get("/v1/orders/", params, getOptions());
  const updateOrder = (params) =>
    api.patch(`/v1/orders/${params.orderId}/`, params, getOptions());
  const getCountOrders = () =>
    api.get("/v1/orders/count_orders/", null, getOptions());
  const getCountByCarrier = () =>
    api.get("/v1/orders/count_by_carrier/", null, getOptions());

  // Order Actions
  const createInvoices = (params) =>
    api.post("/v1/orders/create_invoices/", params, getOptions());
  const cancelInvoices = (params) =>
    api.post("/v1/orders/cancel_invoices/", params, getOptions());
  const setDeliveredInvoices = (params) =>
    api.post("/v1/orders/set_delivered/", params, getOptions());
  const setNotDeliveredInvoices = (params) =>
    api.post("/v1/orders/set_not_delivered/", params, getOptions());
  const addManuallyCreatedParcel = (params) =>
    api.post("/v1/orders/add_manually_created_parcel/", params, getOptions());

  // finances
  const getAccounts = (params) =>
    api.get("/v1/finances/accounts/", params, getOptions());
  const getBusinessEntities = (params) =>
    api.get("/v1/finances/business_entities/", params, getOptions());
  const getCampaigns = (params) =>
    api.get("/v1/finances/campaigns/", params, getOptions());
  const getTransactionCategories = (params) =>
    api.get("/v1/finances/transaction_categories/", params, getOptions());
  const getTransactions = (params) =>
    api.get("/v1/finances/transactions/", params, getOptions());
  const createTransaction = (params) =>
    api.post("/v1/finances/transactions/", params, getOptions());
  const updateTransaction = (params) =>
    api.patch(`/v1/finances/transactions/${params.id}/`, params, getOptions());
  const revertTransaction = (params) =>
    api.post(
      `/v1/finances/transactions/${params.id}/revert/`,
      params,
      getOptions()
    );

  return {
    login,
    getOrders,
    getCountries,
    getCountOrders,
    getCountByCarrier,
    getTags,
    createInvoices,
    cancelInvoices,
    setDeliveredInvoices,
    setNotDeliveredInvoices,
    addManuallyCreatedParcel,
    updateOrder,
    getAccounts,
    getBusinessEntities,
    getCampaigns,
    getTransactionCategories,
    getTransactions,
    createTransaction,
    updateTransaction,
    revertTransaction,
    _api: api,
  };
};

const api = create();

export default api;
