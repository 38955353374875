const CREATE_INVOICE = "CREATE_INVOICE";
const CANCEL_INVOICE = "CANCEL_INVOICE";
const SET_DELIVERED = "SET_DELIVERED";
const SET_NOT_DELIVERED = "SET_NOT_DELIVERED";
const ADD_MANUALLY_CREATED_PARCEL = "ADD_MANUALLY_CREATED_PARCEL";
const UPDATE_ORDER = "UPDATE_ORDER";

export {
  CREATE_INVOICE,
  CANCEL_INVOICE,
  SET_DELIVERED,
  SET_NOT_DELIVERED,
  ADD_MANUALLY_CREATED_PARCEL,
  UPDATE_ORDER,
};
