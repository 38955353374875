import { Redirect, Route } from "react-router-dom";
import { checkAuthorization } from "helpers/helpers";
import PrivateLayout from "components/Layouts/Private";
import PropTypes from "prop-types";
import React from "react";

const PrivateRoute = ({
  component: Component,
  redirect: pathname,
  fetchOrderCounters,
  orderCounters,
  ...rest
}) => {
  const Routes = (props) => {
    if (checkAuthorization() === true) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <PrivateLayout>
              <Component {...rest} {...props} />
            </PrivateLayout>
          )}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Routes />;
};

PrivateRoute.defaultProps = { redirect: "/login" };

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  redirect: PropTypes.string,
};

export default PrivateRoute;
