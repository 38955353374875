import { createGenericSlice } from "helpers/factories";

const campaignsSlice = createGenericSlice("campaigns");

export const { actions } = campaignsSlice;

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  campaignsSlice.actions;

// this is a convention to export reducer by default from slice
export default campaignsSlice.reducer;
