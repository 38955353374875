import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  count: 0,
  page: 0,
  search: "",
  selectedTag: "",
  rowsPerPage: 25,
  requesting: false,
  successful: false,
  needUpdate: true,
  error: null,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    sendRequest(state) {
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
      state.count = action.payload.count;
      state.list = action.payload.results;
      state.needUpdate = false;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
      state.needUpdate = true;
    },
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    setNeedUpdate(state) {
      state.needUpdate = true;
    },
    setNeedUpdateFromStart(state) {
      state.page = 0;
      state.needUpdate = true;
    },
  },
});

// this is a convention to have named exports from slice
export const {
  sendRequest,
  requestSuccess,
  requestError,
  setPage,
  setRowsPerPage,
  setNeedUpdate,
  setNeedUpdateFromStart,
} = ordersSlice.actions;

// this is a convention to export reducer by default from slice
export default ordersSlice.reducer;
