import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  password: "",
  requesting: false,
  successful: false,
  messages: [],
  error: null,
  user: undefined,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    init(state) {
      // we do not need to provide immutable return
      // Redux-Toolkit doing it by itself under the hood.
      // so, we can just mutate state here directly
      // which is quite cool and unusual at the same time.
    },
    sendRequest(state) {
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
      state.user = action.payload;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
  },
});

// this is a convention to have named exports from slice
export const { init, sendRequest, requestSuccess, requestError } =
  loginSlice.actions;

// this is a convention to export reducer by default from slice
export default loginSlice.reducer;
