import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setDrawerOpen(state, action) {
      state.open = action.payload ;
    },
  },
});

// this is a convention to have named exports from slice
export const { setDrawerOpen } = drawerSlice.actions;

// this is a convention to export reducer by default from slice
export default drawerSlice.reducer;
