import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: undefined,
  code: 0,
  location: undefined,
  message: undefined,
  stack: undefined,
  fatal: false,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setGlobalError(state, action) {
      state.type = action.payload.type;
      state.code = action.payload.code;
      state.location = action.payload.location;
      state.message = action.payload.message;
      state.stack = action.payload.stack;
      state.fatal = action.payload.fatal;
    },
    init() {
      return initialState;
    },
  },
});

// this is a convention to have named exports from slice
export const { setGlobalError, init } = errorSlice.actions;

// this is a convention to export reducer by default from slice
export default errorSlice.reducer;
