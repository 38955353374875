import { all, call, put, takeLatest } from "redux-saga/effects";
import { browserRedirect } from "helpers/helpers";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* loginWorker({ payload }) {
  const response = yield call(api.login, payload);

  if (response.ok) {
    const { data } = response;
    localStorage.removeItem("user");
    localStorage.setItem("token", data.access);
    localStorage.setItem(
      "user",
      JSON.stringify({
        id: response.data._id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
      })
    );
    yield put(requestSuccess());
    yield call(browserRedirect, "/");
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}

// Login Watcher
export default function* loginSaga() {
  yield all([takeLatest(sendRequest.type, loginWorker)]);
}
