import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
  list: [],
  requesting: false,
  successful: false,
  error: null,
};

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    sendRequest(state) {
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
      state.count = action.payload.count;
      state.list = action.payload.results;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
  },
});

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  countriesSlice.actions;

// this is a convention to export reducer by default from slice
export default countriesSlice.reducer;
