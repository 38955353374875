import { Redirect, Route } from "react-router-dom";
import { checkAuthorization } from "helpers/helpers";
import PropTypes from "prop-types";
import React from "react";

const AuthRoute = ({ component: Component, redirect: pathname, ...rest }) => {
  const Routes = (props) => {
    if (checkAuthorization() === false) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <div className="authLayout">
              <Component {...rest} {...props} />
            </div>
          )}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname,
            state: { from: props.location },
          }}
        />
      );
    }
  };
  return <Routes />;
};

AuthRoute.defaultProps = { redirect: "/" };

AuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  redirect: PropTypes.string,
};

export default AuthRoute;
