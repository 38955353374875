import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* transactionsWorker({ payload }) {
  const { page, rowsPerPage, query } = payload;
  const response = yield call(api.getTransactions, {
    ...query,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    ordering: "-created",
  });

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}
// Login Watcher
export default function* transactionsSaga() {
  yield all([takeLatest(sendRequest.type, transactionsWorker)]);
}
