import { all } from "redux-saga/effects";
import loginSaga from "./Login/saga";

// orders
import countriesSaga from "../Orders/Countries/saga";
import orderActionsSaga from "../Orders/Actions/saga";
import orderCarriersSaga from "../Orders/Carriers/saga";
import orderCountersSaga from "../Orders/Counters/saga";
import ordersSaga from "../Orders/saga";
import tagsSaga from "../Orders/Tags/saga";

// finances
import financesAccounsSaga from "../Finances/Accounts/saga";
import financesActionsSaga from "../Finances/Actions/saga";
import financesBusinessEntitiesSaga from "../Finances/BusinessEntities/saga";
import financesCampaignsSaga from "../Finances/Campaigns/saga";
import financesTransactionCategoriesSaga from "../Finances/TransactionCategories/saga";
import financesTransactionsSaga from "../Finances/saga";

export function* rootSaga() {
  yield all([
    countriesSaga(),
    loginSaga(),

    financesActionsSaga(),
    financesAccounsSaga(),
    financesBusinessEntitiesSaga(),
    financesCampaignsSaga(),
    financesTransactionCategoriesSaga(),
    financesTransactionsSaga(),

    orderActionsSaga(),
    orderCarriersSaga(),
    orderCountersSaga(),
    ordersSaga(),

    tagsSaga(),
  ]);
}
