import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { ConfirmProvider } from "material-ui-confirm";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import App from "modules/App";
import React from "react";
import ReactDOM from "react-dom";
import store from "config/store";
import theme from "config/theme";

require("dotenv").config();

// license code for X-Grid
LicenseInfo.setLicenseKey(
  "6f7dfbb2c004d69a06a3d8c6d833c35bT1JERVI6MjE3MzIsRVhQSVJZPTE2NDU3ODQzNzIwMDAsS0VZVkVSU0lPTj0x"
);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <SnackbarProvider maxSnack={3}>
          <Provider store={store}>
            <App />
          </Provider>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
