import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from "use-query-params";
import { Route, Router, Switch } from "react-router-dom";
import { browserRedirect } from "helpers/helpers";
import { setGlobalError } from "modules/App/Error/slice";
import AuthRoute from "./authRoutes"; // Private Routes, Will only accessible after Login
import Loading from "components/Layouts/Loading"; // Auth Routes, Will only accessible before login.
import NotFound404 from "components/Layouts/404";
import PrivateRoute from "./privateRoutes";
import React, { Suspense, lazy } from "react";
import api from "services/api";
import history from "config/history";
import store from "config/store";

// Network Error Monitoring
// with apisauce monitors
const errorMonitor = (response) => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    browserRedirect("/");
  }
  if (
    (response.status !== 401 && response.status >= 400 && !response.ok) ||
    response.problem === "NETWORK_ERROR"
  ) {
    store.dispatch(
      setGlobalError({
        type: response.problem,
        code: response.status,
        location: response.config.url,
        message: JSON.stringify(response.data),
        fatal: true,
      })
    );
  }
};
api._api.addMonitor(errorMonitor);

// Catching errors with JS event api
// TODO: Figure that out, it's didn't catch anything now
window.addEventListener("error", (e) => {
  store.dispatch(
    setGlobalError({
      type: "uncaughtException",
      code: 999,
      location: e.target.location.pathname,
      message: e.error.message,
      stack: e.error.stack,
      fatal: true,
    })
  );
});

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

// Lazy loading of all the components.
const Finances = lazy(() => import("../Finances"));
const Orders = lazy(() => import("../Orders"));
const Logout = lazy(() => import("./Logout"));
const Login = lazy(() => import("./Login"));

const App = () => {
  return (
    <Router history={history}>
      <QueryParamProvider
        ReactRouterRoute={Route}
        stringifyOptions={queryStringifyOptions}
      >
        <Suspense fallback={<Loading />}>
          <Switch>
            <AuthRoute path="/login" component={Login} />
            <PrivateRoute path="/logout" component={Logout} />
            <PrivateRoute exact path="/" component={Orders} />
            <PrivateRoute exact path="/finances" component={Finances} />
            <Route path="*" component={NotFound404} />
          </Switch>
        </Suspense>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
