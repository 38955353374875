import { createGenericSlice } from "helpers/factories";

const transactionCategoriesSlice = createGenericSlice("transactionCategories");

export const { actions } = transactionCategoriesSlice;

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  transactionCategoriesSlice.actions;

// this is a convention to export reducer by default from slice
export default transactionCategoriesSlice.reducer;
