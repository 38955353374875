import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* carriersWorker() {
  const response = yield call(api.getCountByCarrier);

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}

export default function* carriersSaga() {
  yield all([takeLatest(sendRequest.type, carriersWorker)]);
}
