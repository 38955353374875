import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/82boxlogo-dark.png";

const NotFound404 = () => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{ height: "100vh", background: "#f5f5f5" }}
  >
    <Grid item xs={11} sm={8} md={6} lg={3} p={3}>
      <Box p={3}>
        <Box p={1} textAlign="center" mb={1}>
          <Typography variant="h6">
            Ooopsie! <strong>Wrong location</strong>!
          </Typography>
        </Box>
        <Box p={1} textAlign="center" mb={4}>
          <img alt="logo" src={logo} style={{ width: 150 }} />
        </Box>
      </Box>
    </Grid>
  </Grid>
);

export default NotFound404;
