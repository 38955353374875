import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "modules/App/rootReducer";
import { rootSaga } from "modules/App/rootSaga";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

export default store;
