import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* countriesWorker() {
  // call api with parameters
  const response = yield call(api.getCountries, { limit: 500 });

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}

export default function* countrisSaga() {
  yield all([takeLatest(sendRequest.type, countriesWorker)]);
}
