import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { init, requestError, requestSuccess, sendRequest } from "./slice";
import { setNeedUpdate } from "modules/Finances/slice";

import {
  CREATE_TRANSACTION,
  REVERT_TRANSACTION,
  UPDATE_TRANSACTION,
} from "./const";

const API = {
  [CREATE_TRANSACTION]: api.createTransaction,
  [REVERT_TRANSACTION]: api.revertTransaction,
  [UPDATE_TRANSACTION]: api.updateTransaction,
};

function* financesActionsWorker({ payload }) {
  const { operation, requestData } = payload;
  const response = yield call(API[operation], requestData);
  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }

  // TODO: pass those with payload as array with tuples
  // [[func, args], [func, args]] - and then execute here in cycle
  yield put(setNeedUpdate());
  yield put(init());
}

export default function* requestsSaga() {
  yield all([takeLatest(sendRequest.type, financesActionsWorker)]);
}
