import { Box, Button, Grid, Typography } from "@mui/material";
import { browserRedirect } from "helpers/helpers";
import { styled } from "@mui/material/styles";
import React from "react";
import logo from "../../assets/82boxlogo-dark.png";

const MonospacedBox = styled(Box)((theme) => ({
  fontSize: "14px",
  fontFamily: ["SourceCodePro", '"Cascadia Code"', '"Courier New"'].join(","),
}));

const StackLineBox = styled(Box)((theme) => ({
  fontSize: "10px",
  color: "#777",
}));

const Error = ({ message, error: errorOriginal }) => {
  // need to have a copy or we can't delete key below
  const error = { ...errorOriginal };

  if (!error["stack"]) {
    delete error["stack"];
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", background: "#f5f5f5" }}
    >
      <Grid item xs={11} sm={11} md={11} lg={11} p={11}>
        <Box p={3}>
          <Box p={1} textAlign="left" mb={1}>
            <Box ml={4} textAlign="left" mb={4}>
              <img alt="logo" src={logo} style={{ width: 150 }} />
            </Box>
            <Box ml={4}>
              <Typography variant="h1">{message}</Typography>
            </Box>
            <MonospacedBox p={4}>
              {Object.keys(error).map((key) => (
                <Box textAlign="left" mb={1}>
                  <Grid container>
                    <Grid xs={2}>
                      <Typography variant="p">
                        <strong>{key}</strong>:
                      </Typography>
                    </Grid>
                    <Grid xs={10}>
                      {key === "stack" ? (
                        typeof error[key] === "string" &&
                        String(error[key])
                          .split("\n")
                          .map((item, key) => {
                            return (
                              <StackLineBox mb={1}>
                                <span key={key} sx={{ marginBottom: "10px" }}>
                                  {item}
                                </span>
                              </StackLineBox>
                            );
                          })
                      ) : (
                        <Typography variant="p">
                          {String(error[key])}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </MonospacedBox>
            <Box ml={4}>
              <Button
                variant="outlined"
                onClick={() => {
                  browserRedirect("/");
                }}
              >
                На главную
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Error;
