import { all, call, put, takeLatest } from "redux-saga/effects";
import { createSlice } from "@reduxjs/toolkit";

const createGenericSlice = (name) => {
  const initialState = {
    count: 0,
    list: [],
    requesting: false,
    successful: false,
    error: null,
  };

  const genericSlice = createSlice({
    name,
    initialState,
    reducers: {
      sendRequest(state) {
        state.requesting = true;
      },
      requestSuccess(state, action) {
        state.requesting = false;
        state.successful = true;
        state.count = action.payload.count;
        state.list = action.payload.results;
      },
      requestError(state, action) {
        state.requesting = false;
        state.successful = false;
        state.error = action.payload;
      },
    },
  });

  return genericSlice;
};

const createGenericSaga = (actions, api) => {
  function* genericWorker() {
    const response = yield call(api);

    if (response.ok) {
      const { data } = response;
      yield put(actions.requestSuccess(data));
    } else {
      const { problem, status } = response;
      yield put(actions.requestError({ problem, status }));
    }
  }

  return function* genericSaga() {
    yield all([takeLatest(actions.sendRequest.type, genericWorker)]);
  };
};

export { createGenericSaga, createGenericSlice };
