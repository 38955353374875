import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { requestError, requestSuccess, sendRequest } from "./slice";

function* tagsWorker() {
  const response = yield call(api.getTags);

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }
}

// Login Watcher
export default function* countersSaga() {
  yield all([takeLatest(sendRequest.type, tagsWorker)]);
}
