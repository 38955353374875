import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  byCarrier: {},
  requesting: false,
  successful: false,
  error: null,
};

const orderCarriersSlice = createSlice({
  name: "orderCarriers",
  initialState,
  reducers: {
    sendRequest(state) {
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
      state.total = action.payload.total;
      state.byCarrier = action.payload.byCarrier;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
  },
});

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  orderCarriersSlice.actions;

// this is a convention to export reducer by default from slice
export default orderCarriersSlice.reducer;
