import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operation: null,
  requestData: {},
  requesting: false,
  successful: false,
  error: null,
};

const actionsSlice = createSlice({
  name: "orderActions",
  initialState,
  reducers: {
    sendRequest(state, action) {
      const { operation, requestData } = action.payload;
      state.operation = operation;
      state.requestData = requestData;
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
    init() {
      return initialState;
    },
  },
});

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError, init } =
  actionsSlice.actions;

// this is a convention to export reducer by default from slice
export default actionsSlice.reducer;
