import { createGenericSlice } from "helpers/factories";

const accountsSlice = createGenericSlice("accounts");

export const { actions } = accountsSlice;

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  accountsSlice.actions;

// this is a convention to export reducer by default from slice
export default accountsSlice.reducer;
