import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { init, requestError, requestSuccess, sendRequest } from "./slice";
import { sendRequest as sendCounterRequest } from "modules/Orders/Counters/slice";
import { setNeedUpdate } from "modules/Orders/slice";

import {
  ADD_MANUALLY_CREATED_PARCEL,
  CANCEL_INVOICE,
  CREATE_INVOICE,
  SET_DELIVERED,
  SET_NOT_DELIVERED,
  UPDATE_ORDER,
} from "./const";

const API = {
  [CREATE_INVOICE]: api.createInvoices,
  [CANCEL_INVOICE]: api.cancelInvoices,
  [SET_DELIVERED]: api.setDeliveredInvoices,
  [SET_NOT_DELIVERED]: api.setNotDeliveredInvoices,
  [ADD_MANUALLY_CREATED_PARCEL]: api.addManuallyCreatedParcel,
  [UPDATE_ORDER]: api.updateOrder,
};

function* actionsWorker({ payload }) {
  const { operation, requestData } = payload;
  const response = yield call(API[operation], requestData);

  if (response.ok) {
    const { data } = response;
    yield put(requestSuccess(data));
  } else {
    const { problem, status } = response;
    yield put(requestError({ problem, status }));
  }

  // TODO: pass those with payload as array with tuples
  // [[func, args], [func, args]] - and then execute here in cycle
  yield put(sendCounterRequest());
  yield put(setNeedUpdate());
  yield put(init());
}

// Login Watcher
export default function* requestsSaga() {
  yield all([takeLatest(sendRequest.type, actionsWorker)]);
}
