import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  byStatus: {
    NEW: 0,
    INVOICE_PENDING: 0,
    INVOICE_ERROR: 0,
    INVOICE_CREATED: 0,
    SENT_TO_POST: 0,
    ACCEPTED_BY_POST: 0,
    DELIVERED: 0,
    NOT_DELIVERED: 0,
  },
  requesting: false,
  successful: false,
  error: null,
};

const orderCountersSlice = createSlice({
  name: "orderCounters",
  initialState,
  reducers: {
    sendRequest(state) {
      state.requesting = true;
    },
    requestSuccess(state, action) {
      state.requesting = false;
      state.successful = true;
      state.total = action.payload.total;
      state.byStatus = action.payload.byStatus;
    },
    requestError(state, action) {
      state.requesting = false;
      state.successful = false;
      state.error = action.payload;
    },
  },
});

// this is a convention to have named exports from slice
export const { sendRequest, requestSuccess, requestError } =
  orderCountersSlice.actions;

// this is a convention to export reducer by default from slice
export default orderCountersSlice.reducer;
